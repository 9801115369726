import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

import Swiper from 'swiper';
import Navigation from 'swiper/modules/navigation.min.mjs';

window.Swiper = Swiper;
window.Navigation = Navigation;


let suggestions = require('suggestions-jquery');
window.suggestions = suggestions;
